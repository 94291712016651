/** @format */

import React, { lazy, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

const Home = lazy(() => import('./RefinanceFB2New'));
const HomeSuccess = lazy(() => import('./RefinanceFB2New/Form/Success'));
const HomeDemo = lazy(() => import('./RefinanceFB2NewDemo'));
const HomeSuccessDemo = lazy(() => import('./RefinanceFB2NewDemo/Form/Success'));
const ThankPageLB = lazy(() => import('./Thanks'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const CookiePolicy = lazy(() => import('./CookiePolicy'));
const HomePerformanceReview = lazy(() => import('./PerformanceReview'));
const HomePerformanceReviewSuccess = lazy(() => import('./PerformanceReview/Form/Success'));

const Router = () => {
  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag("config", "GTM-TC9KTLT", {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [listen]);

  return (
    <Switch>
      <Route exact path="/thank-you">
        <ThankPageLB />
      </Route>
      <Route exact path="/thank-you-demo">
        <ThankPageLB />
      </Route>
      <Route exact path="/thank-you-performance-review">
        <ThankPageLB />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>
      <Route exact path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route exact path="/performance-review/step-success">
        <HomePerformanceReviewSuccess/>
      </Route>
      <Route exact path="/performance-review/">
        <HomePerformanceReview/>
      </Route>
      <Route exact path="/performance-review/*">
        <HomePerformanceReview/>
      </Route>
      <Route exact path="/step-success">
        <HomeSuccess/>
      </Route>
      <Route exact path="/demo/step-success">
        <HomeSuccessDemo/>
      </Route>
      <Route exact path="/demo">
        <HomeDemo />
      </Route>
      <Route exact path="/demo/*">
        <HomeDemo />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/*">
        <Home />
      </Route>
    </Switch>
  );
};

export default Router;
